import Vue from 'vue';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import locale_th from 'dayjs/locale/th' 
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(utc)
dayjs.extend(buddhistEra);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
console.log(locale_th)
Object.defineProperties(Vue.prototype, {
    $dayjs: {
        get() {
            return dayjs
        }
    }
});