import Vue from 'vue'
import {
  ChatClient
} from '@azure/communication-chat'
import {
  AzureCommunicationTokenCredential,
  isCommunicationUserIdentifier
} from '@azure/communication-common'
import { CallClient } from '@azure/communication-calling'
import { EventBus } from '@/plugins/event-bus'
import { postTokenCommService } from '@/api/'
// import store from '@/store'

// Project configurations
export const INITIAL_MESSAGES_SIZE = 2000
export const MAXIMUM_LENGTH_OF_NAME = 10
export const MAXIMUM_LENGTH_OF_MESSAGE = 8000
export const MAXIMUM_LENGTH_OF_TOPIC = 30
export const MAXIMUM_LENGTH_OF_TYPING_USERS = 35
export const MINIMUM_TYPING_INTERVAL_IN_MILLISECONDS = 8000
export const MAXIMUM_INT64 = 9223372036854775807
export const NUMBER_OF_MESSAGES_TO_LOAD = 10
export const PAGE_SIZE = 200
export const PARTICIPANTS_THRESHOLD = 20

const communicationEndpoint = 'https://comservicevwell.communication.azure.com'

const initChat = async () => {
  console.log('initChat')
  try {
    if (window.callAgent) return 
    const userToken = await getToken()
    const options = {
      token: userToken.token,
      tokenRefresher: () => refreshTokenAsync(),
      refreshProactively: true
    }
    // const callClient = new CallClient()
    const tokenCredential = new AzureCommunicationTokenCredential(options)
    // const callAgent = await callClient.createCallAgent(tokenCredential)
    const chatClient = new ChatClient(communicationEndpoint, tokenCredential)
    window.communicationService = userToken
    window.chatClient = chatClient
    // window.callAgent = callAgent 
    window.tokenCredential = tokenCredential
    Object.defineProperty(Vue.prototype, '$communicationService', { value: userToken })
    Object.defineProperty(Vue.prototype, '$chatClient', { value: chatClient })
    // Object.defineProperty(Vue.prototype, '$callAgent', { value: callAgent })
    Object.defineProperty(Vue.prototype, '$tokenCredential', { value: tokenCredential})
    await subscribeForMessage(chatClient)
    await subscribeForTypingIndicator(chatClient)
    await subscribeForReadReceipt(chatClient)
    await subscribeForChatParticipants(chatClient)
    // await subscribeForIncomingCall(callAgent)
  } catch (error) {
    console.log('communication service error')
    console.log(error)
  }
}

const initCall = async () => {
    console.log('initChat')
    try {
      if (window.callAgent) return //alrady init call agent
      if (!window.tokenCredential) { //check credential
        console.log('Token Credential not found')
        const userToken = await getToken()
        const options = {
          token: userToken.token,
          tokenRefresher: () => refreshTokenAsync(),
          refreshProactively: true
        }
        const tokenCredential = new AzureCommunicationTokenCredential(options)
        window.tokenCredential = tokenCredential
      }
      const callClient = new CallClient()
      const callAgent = await callClient.createCallAgent(window.tokenCredential)
      const deviceManager = await callClient.getDeviceManager()
      window.callAgent = callAgent
      window.deviceManager = deviceManager
      Object.defineProperty(Vue.prototype, '$callAgent', { value: callAgent })
      await deviceManager.askDevicePermission({ audio: true });
      await deviceManager.askDevicePermission({ video: true });
      await subscribeForIncomingCall(callAgent)
      await subscribeForCallsUpdated(callAgent)
    } catch (error) {
      console.log('communication service error')
      console.log(error)
    }
  }

// const listChatThread = async (chatClient) => {
//   const threads = chatClient.listChatThreads({ maxPageSize: PAGE_SIZE })
//   const allthreads = []
//   for await (const page of threads.byPage()) {
//     for (const thread of page) {
//       allthreads.push(thread)
//     }
//   }
//   return allthreads
// }

const subscribeForMessage = async (chatClient) => {
  await chatClient.startRealtimeNotifications()
  chatClient.on('chatMessageReceived', async (event) => {
    EventBus.$emit('chatMessageReceived', event)
  })
}

const subscribeForTypingIndicator = async (chatClient) => {
  await chatClient.startRealtimeNotifications()
  chatClient.on('typingIndicatorReceived', async (event) => {
    EventBus.$emit('typingIndicatorReceived', event)
  })
}

const subscribeForReadReceipt = async (chatClient) => {
  await chatClient.startRealtimeNotifications()
  chatClient.on('readReceiptReceived', async (event) => {
    EventBus.$emit('readReceiptReceived', event)
  })
}

const subscribeForChatParticipants = async (chatClient) => {
  await chatClient.startRealtimeNotifications()
  chatClient.on('participantsRemoved', async (event) => {
    EventBus.$emit('participantsRemoved', event)
  })
  chatClient.on('participantsAdded', async (event) => {
    EventBus.$emit('participantsAdded', event)
  })
}

const subscribeForIncomingCall = async (callAgent) => {
  callAgent.on('incomingCall', async (event) => {
    console.log('incomingCall')
    console.log(event)
    EventBus.$emit('incomingCall', event)
  })
}

const subscribeForCallsUpdated = async (callAgent) => {
  callAgent.on('callsUpdated', async (event) => {
    console.log('callsUpdated')
    console.log(event)
    EventBus.$emit('callsUpdated', event)
  })
}

const getToken = async () => {
  try {
    const chat = await postTokenCommService()
        if (chat.data.code === 1) {
            let service = chat.data.result.filter(el=> el.service === 'personalScreeningService')
            if(service.length > 0) {
                return service[0].communicationService
            }
        }
  } catch (error) {
    console.log(error)
  }
  return null
}

const refreshTokenAsync = async () => {
    try {
        const chat = await postTokenCommService()
            if (chat.data.code === 1) {
                let service = chat.data.result.filter(el=> el.service === 'personalScreeningService')
                if(service.length > 0) {
                    return service[0].communicationService.token
                }
            }
      } catch (error) {
        console.log(error)
      }
      return null
}

const isUserMatchingIdentity = (user, communicationUserId) => {
  return isCommunicationUserIdentifier(user) && user.communicationUserId === communicationUserId
}

export {
  initChat,
  initCall,
  isUserMatchingIdentity
}
