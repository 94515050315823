'use strict'

import Vue from 'vue'
import * as Msal from '@azure/msal-browser'
import router from '@/router'
import store from '@/store'
import VueRouter from 'vue-router'
// import { initChat } from '@/plugins/communication'
const { isNavigationFailure, NavigationFailureType } = VueRouter
import { VueOfflineStorage } from 'vue-offline'
import { UAParser } from 'ua-parser-js'
// import { getSites } from '@/api/'
const ua = window.navigator.userAgent
const msie = ua.indexOf("MSIE ")
const msie11 = ua.indexOf("Trident/")
const msedge = ua.indexOf("Edge/")
const parser = new UAParser()
const hardware = parser.getDevice()
const isIE = msie > 0 || msie11 > 0 || hardware.vendor === 'OPPO'
const isEdge = msedge > 0


const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1A_SNWEB_SIGNUP_SIGNIN',
    forgotPassword: 'B2C_1A_SNWEB_PasswordReset',
    editProfile: 'B2C_1A_SNWEB_ProfileEdit',
    signUpSignInWithLine: 'signUpSignInWithLine'
  },
  authorities: {
    signUpSignIn: {
      authority:
        'https://nextervwell.b2clogin.com/nextervwell.onmicrosoft.com/B2C_1A_SignUpOrSignInWithPhoneOrEmail' // https://nextervwell.b2clogin.com/nextervwell.onmicrosoft.com/B2C_1A_TOU_SUSI //https://nextervwell.b2clogin.com/nextervwell.onmicrosoft.com/B2C_1A_SignUpOrSignInWithPhoneOrEmail
    },
    signUpSignInWithLine: {
      authority:
        'https://nextervwell.b2clogin.com/nextervwell.onmicrosoft.com/B2C_1A_SOCIAL_SIGNUP_SIGNIN' // https://nextervwell.b2clogin.com/nextervwell.onmicrosoft.com/B2C_1A_TOU_SUSI //https://nextervwell.b2clogin.com/nextervwell.onmicrosoft.com/B2C_1A_SignUpOrSignInWithPhoneOrEmail
    },
    signUpSignInWithPhone: {
      authority:
        'https://nextervwell.b2clogin.com/nextervwell.onmicrosoft.com/B2C_1A_SIGNUPORSIGNINWITHPHONE' // https://nextervwell.b2clogin.com/nextervwell.onmicrosoft.com/B2C_1A_TOU_SUSI //https://nextervwell.b2clogin.com/nextervwell.onmicrosoft.com/B2C_1A_SignUpOrSignInWithPhoneOrEmail
    },
    signUpSignInWithNewPhone: {
      authority:
        'https://nextervwell.b2clogin.com/nextervwell.onmicrosoft.com/B2C_1A_PH_SUSI_KMSI' // https://nextervwell.b2clogin.com/nextervwell.onmicrosoft.com/B2C_1A_TOU_SUSI //https://nextervwell.b2clogin.com/nextervwell.onmicrosoft.com/B2C_1A_SignUpOrSignInWithPhoneOrEmail
    },
    signUpSignInWithAD: {
      authority:
        'https://nextervwell.b2clogin.com/nextervwell.onmicrosoft.com/B2C_1A_AD_SIGNUP_SIGNIN' // https://nextervwell.b2clogin.com/nextervwell.onmicrosoft.com/B2C_1A_TOU_SUSI //https://nextervwell.b2clogin.com/nextervwell.onmicrosoft.com/B2C_1A_SignUpOrSignInWithPhoneOrEmail
    },
    forgotPassword: {
      authority:
        'https://nextervwell.b2clogin.com/nextervwell.onmicrosoft.com/B2C_1A_SNWEB_PasswordReset'
    },
    editProfile: {
      authority:
        'https://nextervwell.b2clogin.com/nextervwell.onmicrosoft.com/B2C_1A_SNWEB_ProfileEdit'
    },
    changePassword: {
      authorities:
        'https://nextervwell.b2clogin.com/nextervwell.onmicrosoft.com/B2C_1A_SNWEB_PasswordChange'
    }
  },
  authorityDomain: 'nextervwell.b2clogin.com'
}

const apiConfig = {
  b2cScopes: [
    'openid','offline_access','https://nextervwell.onmicrosoft.com/2c991382-8e46-4f46-b9b5-f75ccc45814c/read'
  ],
  webApi: 'https://fabrikamb2chello.azurewebsites.net/hello'
}

const msalConfig = {
  auth: {
    clientId: '2c991382-8e46-4f46-b9b5-f75ccc45814c', // 2fdd06f3-7b34-49a3-a78b-0cf1dd87878e// This is the ONLY mandatory field everything else is optional.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
    knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
    redirectUri: process.env.VUE_APP_AD_URL, // You must register this URI on Azure Portal/App Registration. Defaults to 'window.location.href'.
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. 'sessionStorage' is more secure, but 'localStorage' gives you SSO.
    storeAuthStateInCookie: isIE || isEdge // If you wish to store cache items in cookies as well as browser cache, set this to 'true'.
  },
  system: {
    iframeHashTimeout: 7000,
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case Msal.LogLevel.Error:
            console.error(message)
            return
          case Msal.LogLevel.Info:
            console.info(message)
            return
          case Msal.LogLevel.Verbose:
            console.debug(message)
            return
          case Msal.LogLevel.Warning:
            console.warn(message)
        }
      }
    }
  }
}

/**
 *  Vue Instance Definition
 */

let instance

export const getInstance = () => instance

/**
 *  Vue Instance Initialization
 */

export const useB2C = () => {
  if (instance) return instance

  instance = new Vue({
    data () {
      return {
        b2cClient: null,
        isLoading: true,
        isAuthenticated: false,
        accessToken: null,
        accoutId: '',
        user: {},
        error: null,
        claim: null,
        role: null,
        isSiteAdmin: false,
        isTenantAdmin: false,
        allSite: [],
        authority: null,
        idpPicture: null
      }
    },
    methods: {
      
      async getToken () {
        return await this.b2cClient
          .acquireTokenSilent({
            scopes: [...apiConfig.b2cScopes],
            forceRefresh: false,
            account: this.user.account,
            authority: 'https://nextervwell.b2clogin.com/nextervwell.onmicrosoft.com/'+ this.user.account.idTokenClaims.acr
          })
          .catch(async error => {
            console.log('silent token acquisition fails.')
            if (error instanceof Msal.InteractionRequiredAuthError) {
              // fallback to interaction when silent call fails
              console.log("acquiring token using popup");
              return this.b2cClient.acquireTokenRedirect({
                scopes: [...apiConfig.b2cScopes],
                account: this.user.account,
                authority: 'https://nextervwell.b2clogin.com/nextervwell.onmicrosoft.com/'+ this.user.account.idTokenClaims.acr
              }).catch(error => {
                  console.error(error);
                  router.push({name:'signin'})
              });
            } else {
              console.error(error)
              router.push({name:'signin'})
            }
          })
      },
      changePassword () {
        this.b2cClient.loginRedirect({
          authority: b2cPolicies.authorities.forgotPassword.authority
          // scopes: [...apiConfig.b2cScopes]
        })
      },
      editProfile () {
        this.b2cClient.loginRedirect({
          authority: b2cPolicies.authorities.editProfile.authority
          // scopes: [...apiConfig.b2cScopes]
        })
      },
      logout () {
        this.isAuthenticated = false
        const currentAcc = this.b2cClient.getAccountByHomeId(this.accountId)
        localStorage.clear()
        sessionStorage.clear()
        this.b2cClient.logoutRedirect(currentAcc)
      },
      clearState() {
        localStorage.clear()
        sessionStorage.clear()
      },
      parseJwt (token) {
        var base64Url = token.split('.')[1]
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        }).join(''))
        return JSON.parse(jsonPayload)
      },
      validateInternalRoleCondition (role) {
        if (role && role.length === 0) this.logout()
        // Parse data
        this.role = role[0]
        // console.log(this.role)
        this.role.service = this.role.service.filter(val => val.service === 'personalScreeningService')
        // Check tenant
        //  console.log(this.role)
          this.isAuthenticated = true
          VueOfflineStorage.set("role",this.role);
          VueOfflineStorage.set("isAuthen", true);
          // Check term and condition
          if (!this.role.consent || !this.role.consent.termCondition || !this.role.consent.termCondition.isAccept) { 
           // if(this.role.consent.termCondition.isAccept === false) router.replace({ name: 'signin'}) 
            if(!store.state.tou)router.push({ name: 'tou', params: { mode: 'consent'} }) 
          } else if (!this.role.consent || !this.role.consent.privacyPolicy || !this.role.consent.privacyPolicy.isAccept) { 
            if(!store.state.pn) router.push({ name: 'pn', params: { mode: 'consent'}})
          } else {
            // console.log('selecttenant')
            this.validateTenant()
          }
        // onRedirectCallback()
      },
      validateRoleCondition (role) {
        if (role && role.length === 0) this.logout()
        // Parse data
        this.role = JSON.parse(role[0])
        this.role.service = this.role.service.filter(val => val.service === 'personalScreeningService')
          this.isAuthenticated = true
          VueOfflineStorage.set("role",this.role);
          VueOfflineStorage.set("isAuthen", true);
          // Check term and condition
          if (!this.role.consent || !this.role.consent.termCondition || !this.role.consent.termCondition.isAccept) { 
            if(!this.role.consent || !this.role.consent.termCondition || this.role.consent.termCondition.isAccept === null) {
              if(!store.state.tou) {router.push({ name: 'tou', params: { mode: 'consent'} })}else { this.buildAllSite()}
            }else if (this.role.consent.termCondition.isAccept === false){
              router.replace({ name: 'signin'}) 
            }
          } else if (!this.role.consent || !this.role.consent.privacyPolicy || !this.role.consent.privacyPolicy.isAccept) { 
            if(!store.state.pn) { router.push({ name: 'pn', params: { mode: 'consent'}}) } else {  this.buildAllSite() }
            
          } else if (!this.role.consent || !this.role.consent.cookies || !this.role.consent.cookies.isAccept) { 
            if(!store.state.cookies) { router.push({ name: 'cookies', params: { mode: 'consent'}}) } else {  this.buildAllSite() }
            
          } else {
            // console.log('selecttenant')
            // this.validateTenant()
            this.buildAllSite()
          }
        // onRedirectCallback()
      },
      buildAllSite () {
        for (const service of this.role.service) {
          for (const tenant of service.tenant) {
            for (const site of tenant.site) {
              this.allSite.push({
                ...site,
                tenantId: tenant.tenantId,
                tenantName: tenant.tenantName,
              });
            }
          }
        }
        try {
          const site = VueOfflineStorage.get("site");
          if (!site) {
            for (const service of this.role.service) {
              for (const tenant of service.tenant) {
                if (Array.isArray(tenant.site)) {
                  VueOfflineStorage.set(
                    "site",
                    {...tenant.site[tenant.site.length - 1], tenantId: tenant.tenantId,
                      tenantName: tenant.tenantName}
                  );
                  VueOfflineStorage.set("tenant", tenant);
                }
              }
            }
            this.validatePage()
            // router.push({ name: 'main' }) 
          } else {
            let sitePermission = this.allSite.filter(item => item.siteId === site.siteId)
            if (sitePermission.length === 0)  {
              if(this.allSite.length === 0) {
                VueOfflineStorage.set("site", null);
                VueOfflineStorage.set("tenant", null);
              }else{
                VueOfflineStorage.set("site", this.allSite[0]);
                VueOfflineStorage.set("tenant", this.allSite[0]);
              }
            } 
            this.validatePage()
            // router.push({ name: 'main' }) 
          }
        } catch (error) {
          console.log(error);
        }
      },
      validateTenant () {
        if (this.role.service.length === 0) {
          VueOfflineStorage.set("tenant",null)
          VueOfflineStorage.set("site",null)
          router.push({ name: 'main' }) 
        } 
        else if (this.role.service[0].tenant.length === 1 ) {
            console.log('tenent 1')
             VueOfflineStorage.set("tenant",this.role.service[0].tenant[0]);
             this.validateSite()
        }
        else if (this.role.service[0].tenant.length > 1 ) {
          const currentTenant = VueOfflineStorage.get('tenant')
          // console.log('current tenant')
          if (currentTenant) {
            if (this.role.service[0].tenant.filter(data => data.tenantId === currentTenant.tenantId).length > 0) {
              console.log('has current tenant')
              //VueOfflineStorage.set("tenant",currentTenant);
              // validate permission true
              // sessionStorage.setItem('Site', JSON.stringify(selectedTanent.site.filter(data => data.siteId === JSON.parse(currentSite).siteId)[0]))
              // VueOfflineStorage.set('site',selectedTanent.site.filter(data => data.siteId === currentSite.siteId)[0])
              // this.isSiteAdmin = selectedTanent.site.filter(data => data.siteId === currentSite.siteId)[0].isSiteAdmin
              this.validateSite()
            } else {
              router.push({ name: 'selectsite' })
              // validate permission false and go to select site page
            }
          } else {
            router.replace({name: 'selecttenant'});
          }
        } else {
          VueOfflineStorage.set("tenant",null)
          VueOfflineStorage.set("site",null)
          router.push({ name: 'main' }) 
          // VueOfflineStorage.set("tenant",this.role.service[0].tenant[0]);
          // this.validateSite()
        }
      },
      validateSite () {
        if (this.role.service.length === 0) {
          VueOfflineStorage.set("tenant",null)
          VueOfflineStorage.set("site",null)
          router.push({ name: 'main' }) 
        } else {
        const selectedTanent = VueOfflineStorage.get("tenant")
        if (selectedTanent.site) {
          if (selectedTanent.site.length === 1) {
            // Only one site
            //sessionStorage.setItem('Site', JSON.stringify(selectedTanent.site[0]))
            VueOfflineStorage.set("site",selectedTanent.site[0])
            this.isSiteAdmin = selectedTanent.site[0].isSiteAdmin
            this.validatePage()
          } else if (selectedTanent.site.length > 1) {
            // More than one site
            console.log('more than one site')
            const currentSite = VueOfflineStorage.get('site')
            if (currentSite) {
              // Has current site
              // if (this.role.site.filter(data => data.siteId === JSON.parse(currentSite).siteId).length > 0) {
              // console.log(selectedTanent)
              if (selectedTanent.site.filter(data => data.siteId === currentSite.siteId).length > 0) {
                // validate permission true
                console.log('validate permission')
                // sessionStorage.setItem('Site', JSON.stringify(selectedTanent.site.filter(data => data.siteId === JSON.parse(currentSite).siteId)[0]))
                VueOfflineStorage.set('site',selectedTanent.site.filter(data => data.siteId === currentSite.siteId)[0])
                this.isSiteAdmin = selectedTanent.site.filter(data => data.siteId === currentSite.siteId)[0].isSiteAdmin
                this.validatePage()
              } else {
                router.push({ name: 'selectsite' })
                // validate permission false and go to select site page
              }
            } else {
              router.push({ name: 'selectsite' })
              // go to select site page
            }
          } else {
            VueOfflineStorage.set("site",null)
            router.push({ name: 'main' })
          }
        } else {
          // Unavailable site
          VueOfflineStorage.set("site",null)
          router.push({ name: 'main' })
        }
      }
      },
      async validatePage () {
        // router.push({ name: 'main' })
        const lastRouteName = sessionStorage.getItem('LS_ROUTE_KEY')
        const lastRouteParams = sessionStorage.getItem('LS_ROUTE_PARAMS')
        // await initChat()
        if (lastRouteName) {
          router.push({ name: lastRouteName, params: JSON.parse(lastRouteParams) }).catch(failure => {
            if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
              router.push({ name: 'main' })
            }
          })
        } else {
          router.push({ name: 'main' })
        }
      },
      signInWithEmail () {
          this.authority = b2cPolicies.authorities.signUpSignIn.authority
          this.b2cClient.loginRedirect({
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            scopes: [...apiConfig.b2cScopes],
            prompt: 'login'
          })
      },
      signInWithLine () {
        this.authority = b2cPolicies.authorities.signUpSignInWithLine.authority
        this.b2cClient.loginRedirect({
          authority: b2cPolicies.authorities.signUpSignInWithLine.authority,
          scopes: [...apiConfig.b2cScopes],
          prompt: 'login'
        })
      },
      signInWithPhone() {
      this.authority = b2cPolicies.authorities.signUpSignInWithPhone.authority
      this.b2cClient.loginRedirect({
        authority: b2cPolicies.authorities.signUpSignInWithPhone.authority,
        scopes: [...apiConfig.b2cScopes],
        prompt: 'login'
      })
      },
      signInWithNewPhone() {
        this.authority = b2cPolicies.authorities.signUpSignInWithNewPhone.authority
        this.b2cClient.loginRedirect({
          authority: b2cPolicies.authorities.signUpSignInWithNewPhone.authority,
          scopes: [...apiConfig.b2cScopes],
          extraQueryParameters: { ui_locales: 'th' },
          prompt: 'login'
        })
        },
      signInWithAD() {
          this.authority = b2cPolicies.authorities.signUpSignInWithAD.authority
            this.b2cClient.loginRedirect({
              authority: b2cPolicies.authorities.signUpSignInWithAD.authority,
              scopes: [...apiConfig.b2cScopes],
              extraQueryParameters: { ui_locales: 'th' },
              prompt: 'login'
            })
        }
    },
    async created () {
      this.b2cClient = new Msal.PublicClientApplication(msalConfig)
      // this.isLoading = true
      VueOfflineStorage.set("isAuthen", false);
     //  this.isAuthenticated = false
      try {
        let tokenResponse = await this.b2cClient.handleRedirectPromise()
        let accountObj
        if (tokenResponse) {
          accountObj = tokenResponse.account
          this.accountId = tokenResponse.account.homeAccountId
          this.accessToken = tokenResponse.accessToken
          this.user = tokenResponse
        } else {
          const currentAccounts = this.b2cClient.getAllAccounts();
          if (!currentAccounts || currentAccounts.length > 1) {
            let filterAccount = currentAccounts.filter(item=>item.idTokenClaims.aud === '2c991382-8e46-4f46-b9b5-f75ccc45814c')
              if(filterAccount.length>0) {
                accountObj = filterAccount[0];
              }
          }  else if (currentAccounts.length === 1) {
            if(currentAccounts[0].idTokenClaims.aud === '2c991382-8e46-4f46-b9b5-f75ccc45814c') {
              accountObj = currentAccounts[0];
            }
          }
        }

         if (accountObj && tokenResponse) {
          console.log(
            '[AuthService.init] Got valid accountObj and tokenResponse'
          )
          this.accessToken = tokenResponse.accessToken
          this.accountId = tokenResponse.account.homeAccountId
          this.user = tokenResponse
          this.claim = this.parseJwt(tokenResponse.accessToken)
          this.idpPicture = this.claim.picture
          this.validateRoleCondition(this.claim.role)
        } else if (accountObj) {
          console.log(
            '[AuthService.init] Got valid only accountObj'
          )

          tokenResponse = await this.b2cClient
          .acquireTokenSilent({
            scopes: [...apiConfig.b2cScopes],
            account: accountObj,
            authority: 'https://nextervwell.b2clogin.com/nextervwell.onmicrosoft.com/'+ accountObj.idTokenClaims.acr
          })
          .catch(async error => {
            console.log('silent token acquisition fails.')
            if (error instanceof Msal.InteractionRequiredAuthError) {
              // fallback to interaction when silent call fails
              // console.log('acquiring token using redirect')
              // return this.b2cClient.acquireTokenRedirect({
              //   scopes: [...apiConfig.b2cScopes],
              //   account: accountObj,
              //   authority: 'https://nextervwell.b2clogin.com/nextervwell.onmicrosoft.com/'+ accountObj.idTokenClaims.acr
              // }).catch(error => {
              //     console.error(error);
              // });
              router.push({name:'signin'})
            } else {
              console.error(error)
              this.clearState()
              router.push({name:'signin'})
            }
          })

          accountObj = tokenResponse.account
          this.accessToken = tokenResponse.accessToken
          this.accountId = tokenResponse.account.homeAccountId
          this.user = tokenResponse
          this.claim = this.parseJwt(tokenResponse.accessToken)
          // console.log(this.claim)
          this.idpPicture = this.claim.picture
          this.validateRoleCondition(this.claim.role)
        } else {
          console.log(
            '[AuthService.init] No accountObject or tokenResponse present. User must now login.'
          )
          //this.clearState()
          router.push({name:'signin'})
        }
      } catch (error) {
        if (error.errorMessage) {
          if (error.errorMessage.indexOf('AADB2C90118') > -1) {
            this.changePassword()
            // router.push({name:'signin'})
          } else {
            this.clearState()
            router.push({name:'signin'})
          }
        }
      } finally {
        this.isLoading = false
      }
    }
  })

  return instance
}

/**
 *  Vue Plugin Definition
 */

export const B2CPlugin = {
  install (Vue, options) {
    Vue.prototype.$auth = useB2C(options)
  }
}
