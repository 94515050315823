<template>
 <transition :name="transitionName">
  <router-view></router-view>
 </transition>
</template>

<script>
//import HelloWorld from './components/HelloWorld';

export default {
 name: 'App',
 data() {
   return {
     transitionName: 'slide-left'
   }
 },
   watch: {
        '$route' (to, from) {
    const toDepth = to.path.split('/').length
    const fromDepth = from.path.split('/').length
    this.transitionName = toDepth < fromDepth ? 'slide-y' : 'slide-y'
  }
  }
  
};
</script>

