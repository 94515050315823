import Vue from 'vue'
import Vuex from 'vuex'
import { VueOfflineStorage } from 'vue-offline'
//import user from '@/store/modules/user'
Vue.use(Vuex)

import { getField, updateField } from 'vuex-map-fields';

import VuexPersistence from 'vuex-persist'
const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default new Vuex.Store({
  plugins: [vuexLocal.plugin],
  namespaced: true,
  state: {
    citizenObj: {
      id_number:'',
      name_th:'',
      address_th:''
    },
    formOffline: [],
    formDraft: [],
    kyc: null,
    visit: null,
    pn: false,
    tou: false,
    cookies: false,
    chatToken: null,
    chatUser: null
  },
  mutations: {
    updateField
  },
  actions: {
  },
  getters: {
    getField,
    getFormDraft(state){
      let role = VueOfflineStorage.get('role')
      return state.formDraft.filter(item=> item[0]===role.uuid.mobile) 
    }
  }
})
