import (/* webpackPreload: true */ '@mdi/font/css/materialdesignicons.css');
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import light from './vuetify/theme'
Vue.use(Vuetify);

// import icon
import TakePhoto from '@/components/icon/TakePhoto.vue'
import Step from '@/components/icon/Step.vue'
import Line from "@/components/icon/Line";
import Phone from "@/components/icon/Phone";
import Lang from "@/components/icon/Lang";
import Noti from "@/components/icon/Noti";
import Policy from "@/components/icon/Policy";
import Info from "@/components/icon/Info";
import Profile from "@/components/icon/Profile";
import Site from "@/components/icon/Site";

Vuetify.config.silent = false

const darkMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

darkMediaQuery.addEventListener('change', (e) => {
  console.log(e)
});

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    dark: false,
    themes: { 
      light: light
     },
  },
    icons: {
        iconfont: 'mdi', // default - only for display purposes
        values: {
          lang: {
            component: Lang
          },
          noti: {
            component: Noti
          },
          policy: {
            component: Policy
          },
          info: {
            component: Info
          },
          profile: {
            component: Profile
          },
          site: {
            component: Site
          },
          takephoto: { // name of our custom icon
            component: TakePhoto, // our custom component
          },
          step: {
            component: Step
          },
          line: {
            component: Line
          },
          phone: {
            component: Phone
          },
          clear:'mdi-close-circle'
        },
      },
});
