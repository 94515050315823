import Vue from 'vue'
import VueRouter from 'vue-router'
import { VueOfflineStorage } from 'vue-offline'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'main',
    component: () => import(/* webpackChunkName: "install" */ '../views/Main.vue'),
    meta: {
      authRequired: true
    },
  },
  {
    path: '/main',
    name: 'MainLayout',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "mainlayout" */ '../layouts/Main.vue'),
    meta: {
      authRequired: true
    },
    children: [
      {
        path: 'home',
        name: 'home',
        redirect: { name: 'main' },
        // component: () => import(/* webpackChunkName: "home" */'@/views/Home'),
        meta: {
          authRequired: true
        },
      },
      {
        path: 'addfriend',
        name: 'addfriend',
        component: () => import(/* webpackChunkName: "addfriend" */'@/views/AddFriend'),
        meta: {
          authRequired: true
        },
      }
    ]
  },
  {
    path: '/setting',
    name: 'setting',
    component: () => import(/* webpackChunkName: "setting" */'@/views/Setting'),
    meta: {
      authRequired: true
    },
  },
  {
    path: '/inbox',
    name: 'inbox',
    component: () => import(/* webpackChunkName: "inbox" */'@/views/Inbox'),
    meta: {
      authRequired: true
    },
  },
  {
    path: '/laboratory',
    name: 'laboratory',
    component: () => import(/* webpackChunkName: "laboratory" */'@/views/Laboratory'),
    meta: {
      authRequired: true
    },
  },
  {
    path: '/setting/lang',
    name: 'lang',
    meta: {
      authRequired: true
    },
    component: () => import(/* webpackChunkName: "lang" */'@/views/Lang'),
  },
  {
    path: '/inbox/message/',
    name: 'message',
    props: true,
    meta: {
      authRequired: true
    },
    component: () => import(/* webpackChunkName: "message" */'@/views/Message'),
  },
  {
    path: '/formlist',
    name: 'formlist',
    meta: {
      authRequired: true
    },
    component: () => import(/* webpackChunkName: "form" */'@/views/FormList'),
  },
  {
    path: '/camera',
    name: 'camera',
    meta: {
      authRequired: true
    },
    component: () => import(/* webpackChunkName: "camera" */'@/views/Camera'),
  },
  {
    path: '/qrscan',
    name: 'qrscan',
    meta: {
      authRequired: true
    },
    component: () => import(/* webpackChunkName: "qrscan" */'@/views/QRcodeScan'),
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    meta: {
      authRequired: true
    },
    component: () => import(/* webpackChunkName: "onboarding" */'@/views/Onboarding'),
  }
  ,
  {
    path: '/user',
    name: 'user',
    meta: {
      authRequired: true
    },
    component: () => import(/* webpackChunkName: "onboarding" */'@/views/User'),
  },
  {
    path: '/shopping',
    name: 'shopping',
    meta: {
      authRequired: true
    },
    component: () => import(/* webpackChunkName: "qrscan" */'@/views/Shopping'),
  },
  {
    path: '/form',
    name: 'form',
    meta: {
      authRequired: true
    },
    component: () => import(/* webpackChunkName: "form" */'@/layouts/Form'),
    children: [
      {
        path: 'info',
        name: 'info',
        props: true,
        component: () => import(/* webpackChunkName: "info" */'@/views/Info'),
        meta: {
          authRequired: true
        },
      },
      {
        path: 'inputform',
        name: 'inputform',
        props: true,
        component: () => import(/* webpackChunkName: "inputform" */'@/views/InputForm'),
        meta: {
          authRequired: true
        },
      },
      {
        path: 'complete',
        name: 'complete',
        component: () => import(/* webpackChunkName: "complete  " */'@/views/Complete'),
        meta: {
          authRequired: true
        },
      }
    ]
  },
  {
    path: '/form/editform',
    name: 'editform',
    props: true,
    component: () => import(/* webpackChunkName: "inputform" */'@/views/EditForm'),
    meta: {
      authRequired: true
    },
  },
  {
    path:'/appointment',
    name: '',
    meta: {
      authRequired: true
    },
    component: () => import(/* webpackChunkName: "booking" */ '../layouts/Booking.vue'),
    children:[
      {
        path:'create',
        name:'makeappointment',
        component: () => import(/* webpackChunkName: "bookingview" */'@/views/BookingView.vue')
      },
      {
        path:'history',
        name:'appointmenthistory',
        component: () => import(/* webpackChunkName: "bookinghistory" */'@/views/BookingHistory.vue')
      },
    ]
  },
  {
    path: '/appointment/success/:userFullname/:perDate/:department',
    name: 'appointmentsuccess',
    component: () => import(/* webpackChunkName: "bookingsuccess" */'@/views/BookingSuccess.vue'),
    meta: {
      authRequired: true
    },
  },
  {
    path: '/appointment/cancel',
    name: 'appointmentcancel',
    component: () => import(/* webpackChunkName: "bookingcancel" */'@/views/BookingCancel.vue'),
    meta: {
      authRequired: true
    },
  },
  {
    path: '/healthrecord',
    name: 'healthrecord',
    component: () => import(/* webpackChunkName: "healthrecord" */'@/views/HealthRecord.vue'),
    meta: {
      authRequired: true
    },
  },
  {
    path: '/healthrecord/bpm',
    name: 'blood_pressure',
    component: () => import(/* webpackChunkName: "savebpm" */'@/views/SaveBPM.vue'),
    meta: {
      authRequired: true
    },
  },
  {
    path: '/healthrecord/bcm',
    name: 'body_component',
    component: () => import(/* webpackChunkName: "body_component" */'@/views/SaveBodyComponent.vue'),
    meta: {
      authRequired: true
    },
  },
  {
    path: '/healthrecord/oxymetry',
    name: 'oxymetry',
    component: () => import(/* webpackChunkName: "oxymetry" */'@/views/SaveOxymetry.vue'),
    meta: {
      authRequired: true
    },
  },
  {
    path: '/healthrecord/glucose',
    name: 'glucose',
    component: () => import(/* webpackChunkName: "glucose" */'@/views/SaveGlucose.vue'),
    meta: {
      authRequired: true
    },
  },
  {
    path: '/healthrecord/temperature',
    name: 'temperature',
    component: () => import(/* webpackChunkName: "temperature" */'@/views/SaveTemperature.vue'),
    meta: {
      authRequired: true
    },
  },
  {
    path: '/healthrecord/history/bpm',
    name: 'bpmhistory',
    component: () => import(/* webpackChunkName: "bpmhistory" */'@/views/BPMHistory.vue'),
    meta: {
      authRequired: true
    },
  },
  {
    path: '/healthrecord/history/oxymetry',
    name: 'oxymetryhistory',
    component: () => import(/* webpackChunkName: "oxymetryhistory" */'@/views/OxymetryHistory.vue'),
    meta: {
      authRequired: true
    },
  },
  {
    path: '/healthrecord/history/glucose',
    name: 'glucosehistory',
    component: () => import(/* webpackChunkName: "glucosehistory" */'@/views/GlucoseHistory.vue'),
    meta: {
      authRequired: true
    },
  },
  {
    path: '/healthrecord/history/temperature',
    name: 'temperaturehistory',
    component: () => import(/* webpackChunkName: "temperaturehistory" */'@/views/TemperatureHistory.vue'),
    meta: {
      authRequired: true
    },
  },
  {
    path: '/healthrecord/history/bodycomponent',
    name: 'bodycomponenthistory',
    component: () => import(/* webpackChunkName: "bodycomponenthistory" */'@/views/BodyComponentHistory.vue'),
    meta: {
      authRequired: true
    },
  },
  {
    path: '/kyc',
    name: 'kyc',
    component: () => import(/* webpackChunkName: "kyc" */'@/views/Kyc.vue'),
    meta: {
      authRequired: true
    },
  },
  {
    path: '/chat',
    name: 'chat',
    component: () => import(/* webpackChunkName: "chat" */'@/views/Chat.vue'),
    meta: {
      authRequired: true
    },
  },
  {
    path: '/chat/:topic/:threadId',
    name: 'chatthread',
    props: true,
    component: () => import(/* webpackChunkName: "chatarea" */'@/views/ChatArea.vue'),
    meta: {
      authRequired: true
    },
  },
  {
    path:'/recorddaily',
    name: 'recorddaily',
    meta: {
      authRequired: true
    },
    component: () => import(/* webpackChunkName: "recorddaily" */ '../layouts/RecordDaily.vue'),
    children:[
      {
        path:'waiting',
        name:'waiting',
        component: () => import(/* webpackChunkName: "waiting" */'@/views/Waiting')
      },
      {
        path:'done',
        name:'done',
        component: () => import(/* webpackChunkName: "done" */'@/views/Done')
      },
      {
        path:'savedraft',
        name:'savedraft',
        component: () => import(/* webpackChunkName: "savedraft" */'@/views/SaveDraft')
      }
    ]
  },
  {
    path: 'site',
    name: 'selectsite',
    component: () => import(/* webpackChunkName: "site" */'@/views/SelectSite.vue'),
    meta: {
      authRequired: true
    },
  },
  {
    path: 'about',
    name: 'about',
    component: () => import(/* webpackChunkName: "site" */'@/views/About.vue'),
    meta: {
      authRequired: false
    },
  },
  {
    path: 'patient',
    name: 'patient',
    component: () => import(/* webpackChunkName: "patient" */'@/views/Patient.vue'),
    meta: {
      authRequired: false
    },
  },
   {
        path: 'signin',
        name: 'signin',
        component: () => import(/* webpackChunkName: "signin" */'@/views/SignIn'),
        meta: {
          authRequired: false
        },
      },
  {
    path: '/welcome',
    name: 'WelcomeLayout',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "welcome" */ '../layouts/Welcome.vue'),
    meta: {
      authRequired: false
    },
    children: [
     
      // {
      //   path: 'otp/:mobile',
      //   name: 'otp',
      //   component: () => import(/* webpackChunkName: "otp" */'@/views/OTP'),
      //   props: true,
      //   meta: {
      //     authRequired: false
      //   },
      // },
      {
        path: 'pn/:mode',
        props: true,
        name: 'pn',
        component: () => import(/* webpackChunkName: "pn" */'@/views/PN'),
        meta: {
          authRequired: false
        },
      },
      {
        path: 'tou/:mode',
        props: true,
        name: 'tou',
        component: () => import(/* webpackChunkName: "tou" */'@/views/TOU'),
        meta: {
          authRequired: false
        },
      },
      {
        path: 'cookies/:mode',
        props: true,
        name: 'cookies',
        component: () => import(/* webpackChunkName: "cookies" */'@/views/CookiesConsent'),
        meta: {
          authRequired: false
        },
      },
      {
        path: 'tenant',
        name: 'selecttenant',
        component: () => import(/* webpackChunkName: "tenant" */'@/views/SelectTenant.vue'),
        meta: {
          authRequired: false
        },
      }
    ],
  },
  {
    path: '/:pathMatch(.*)*', 
    name: 'NotFound', 
    redirect: '/main/home'
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }

})

const DEFAULT_TITLE = 'HEALTHCARE'

router.beforeEach((to,form,next) => {
  let isAuthen = VueOfflineStorage.get('isAuthen')
  // if (to.meta.authRequired && isAuthen) { next() } 
  if (to.matched.some(record => record.meta.authRequired)) {
    if (isAuthen) {
      sessionStorage.setItem('LS_ROUTE_KEY', to.name)
      if (to.params) {
        sessionStorage.setItem('LS_ROUTE_PARAMS', JSON.stringify(to.params))
      }
      next()
    }else{
      console.log('block')
    }
  } else {
    next()
    console.log('public')
  }
  document.title = to.meta.title || DEFAULT_TITLE
})

export default router
