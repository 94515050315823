
const URL = process.env.VUE_APP_URL_BASE_PRIVATE
const headers = {
   'Content-Type': 'application/json',
   'Accept': 'application/json',
   'x-functions-key': process.env.VUE_APP_CODE_BASE_PRIVATE
 }
// import FormData from 'form-data';

export function user (cb, errorCb) {
    window.axios({
      method: 'get',
      url: URL+'user',
      headers: headers
    }).then(response => {
      cb(response)
    }).catch(error => {
      errorCb(error)
    })
}
export function patchUser (param, data,cb, errorCb) {
  window.axios({
    method: 'patch',
    url: URL+'user?id='+param.id+"&_etag="+param.etag,
    headers: headers,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getProfilePicture (data,cb, errorCb) {
    window.axios({
      method: 'get',
      url: URL+'profile-picture?tenantId='+data.tenantId+'&siteId='+data.siteId+'&profilePicture='+data.profilePicture,
      headers: headers
    }).then(response => {
      cb(response)
    }).catch(error => {
      errorCb(error)
    })
}

export function uploadProfilePicture(data,cb,errorCb){
    window.axios({
        method: 'post',
        url: URL+'profile-picture',
        headers: headers,
        data: data
      }).then(response => {
        cb(response)
      }).catch(error => {
        errorCb(error)
      })
}

export function getForm(data){
    return window.axios({
        method: 'get',
        url: URL+'form?tenantId='+data.tenantId+'&siteId='+data.siteId,
        headers: headers
      })
}

export function getFormResult(data,cb,errorCb){
    window.axios({
        method: 'get',
        url: URL+'form-result?tenantId='+data.tenantId+'&siteId='+data.siteId+'&offset='+data.offset+'&limit='+data.limit,
        headers: headers
    }).then(response => {
        cb(response)
      }).catch(error => {
        errorCb(error)
      })
}

export async function postAwaitFormResult({data}){
  try{
    const resp = await window.axios({
        method: 'post',
        url: URL+'form-result',
        headers: headers,
        data: data
    })
    return resp
  }catch (error){
    console.log(error)
    throw error
  }
}

export function postFormResult({data},cb,errorCb){
  window.axios({
      method: 'post',
      url: URL+'form-result',
      headers: headers,
      data: data
  }).then(response => {
      cb(response)
    }).catch(error => {
      errorCb(error)
    })
}

export function ocrIdcard(data,cb,errorCb){
    window.axios({
        method: 'post',
        url: URL+'ocr-idcard',
        headers: headers,
        data: data
    }).then(response => {
        cb(response)
      }).catch(error => {
        errorCb(error)
      })
}

export function pdpaContent(data,cb,errorCb){
    window.axios({
        method: 'get',
        url: URL+'content?contentType='+data,
        headers: headers
    }).then(response => {
        cb(response)
      }).catch(error => {
        errorCb(error)
      })
}

export function consent(data,cb,errorCb){
    window.axios({
        method: 'post',
        url: URL+'consent',
        headers: headers,
        data: data
    }).then(response => {
        cb(response)
      }).catch(error => {
        errorCb(error)
      })
}

export function announcement(data){
  return window.axios({
    method: 'get',
    url: URL+'announcement?tenantId='+data.tenantId+'&siteId='+data.siteId+'&offset='+data.offset+'&limit='+data.limit,
    headers: headers
  })
}

export function announcementBanner(data){
   return window.axios({
        method: 'get',
        url: URL+'announcement-banner?tenantId='+data.tenantId+'&siteId='+data.siteId+'&bannerName='+data.bannerName,
         headers: headers
    })

}

export function fromLogo(data){
  return window.axios({
    method: 'get',
    url: URL+'form-logo?tenantId='+data.tenantId+'&siteId='+data.siteId+'&formLogo='+data.formLogo,
     headers: headers
})
}

export function ocrMeddevice(param,formData,cb,errorCb){
  // var data = new FormData();
  // data.append('file', encode);
  // console.log(data.getHeaders())
  window.axios({
      method: 'post',
      url: URL+'ocr-meddevice?modelId='+param.modelId+'&appKey=' + param.appKey,
      headers: {...headers, 'Content-Type':'multipart/form-data'},
      data: formData
  }).then(response => {
      cb(response)
    }).catch(error => {
      errorCb(error)
    })
}

export function updateUserProfile (param,data,cb,errorCb) {
  window.axios({
    method: 'patch',
    url: URL+'user?id='+param.id+'&_etag='+param.etag,
    data: data,
    headers: headers
}).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postService (data,cb,errorCb) {
  window.axios({
    method: 'post',
    url: URL+'service',
    data: data,
    headers: headers
}).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function deleteService(param){
  return  window.axios({
    method: 'delete',
    url: URL+'service?tenantId=' + param.tenantId + '&siteId=' + param.siteId,
    headers: headers
})
}

export function getSiteContent (param,cb,errorCb) {
  window.axios({
    method: 'get',
    url: URL+'site-content?tenantId=' + param.tenantId + '&siteId=' + param.siteId,
    headers: headers
}).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postSiteConsent (data,cb,errorCb) { 
  window.axios({
    method: 'post',
    url: URL+'site-consent',
    data: data,
    headers: headers
}).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getKYC (param,cb,errorCb) { 
  window.axios({
    method: 'get',
    url: URL+'cms-mobile?group_id='+param.group_id+'&token='+param.token+'&rid='+param.rid+'&uuid='+ param.uuid + '&email='+param.email+'&phone='+param.phone+'&citizenId='+param.citizenId+'&tenantId='+param.tenantId,
    headers: headers
}).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getCMSPatient (param) {
  return window.axios({
    method: 'get',
    url: URL+'cms-patients?group_id='+param.groupId+'&patient_stage=X&limit_list=1&cardid=&keyword='+param.keyword,
    headers: headers
  })
}


export function getCMSShifts (param) {
  return window.axios({
    method: 'get',
    url: URL+'cms-shifts?user_id=0&group_id='+param.groupId+'&user_group_list='+param.user_group_list+'&start_date='+param.start_date+'&ot_status=',
    headers: headers
  })
}

export function getCMSGroup (param) {
  return window.axios({
    method: 'get',
    url: URL+'cms-group?group_id='+param.groupId+'&group_stock=0&group_stamp=0',
    headers: headers
  })
}

export function postCMSVisits (data,cb,errorCb) {
  window.axios({
    method: 'post',
    url: URL+'cms-visits',
    headers: headers,
    data: data
}).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getCMSDiag (param) {
  return window.axios({
    method: 'get',
    url: URL+'cms-diag?visit_id='+param.visitId+'&rid='+param.rid+'&cont=0&keyword=',
    headers: headers
})
}

export function getCMSLabel (param) {
  return window.axios({
    method: 'get',
    url: URL+'cms-label?group_stock='+ param.groupId+'&user_id=0&visit_id=' +param.visitId+'&order_id_list&per_status=T&transfer_dt=',
    headers: headers
})
}

export function getVitalSign (param) {
  return window.axios({
    method: 'get',
    url: URL+'vitalsign?groupId='+param.groupId,
    headers: headers
})
}

export function getVitalSignHistory (param) {
  return window.axios({
    method: 'get',
    url: URL+'vitalsign?vType='+param.vType+'&' + param.startDate + '&' + param.endDate,
    headers: headers
})
}

export function postVitalSign (data,cb,errorCb) {
  window.axios({
    method: 'post',
    url: URL+'vitalsign',
    headers: headers,
    data: data
}).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getBanner () {
  return window.axios({
    method: 'get',
    url: URL+'shop-banner?shop=',
    headers: headers
})
}

export function serviceAuthen (data,cb,errorCb)
 {
  window.axios({
    method: 'post',
    url: URL+'service-authen',
    headers: headers,
    data: data
}).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
 }

export function cmsPatientPicture (param) {
  return window.axios({
    method: 'get',
    url: URL+'cms-patient-picture?groupId='+param.groupId+'&profilePicture=' + param.profilePicture,
    headers: headers
  })
}

export function getNewsInbox (param) {
  return window.axios({
    method: 'get',
    url: URL+'news-inbox?offset='+param.offset+'&limit='+param.limit,
    headers: headers
  })
}

export function getNewsFile (param) {
  return window.axios({
    method: 'get',
    url: URL+'news-file?fileName=' + param.fileName,
    headers: headers
  })
}

export function postNewsLog (data,cb,errorCb) {
  window.axios({
    method: 'post',
    url: URL+'news-log',
    headers: headers,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postTokenCommService (){
  return window.axios({
    method: 'post',
    url: URL+'token-comservice',
    headers: headers
  })
}

export function uploadChatImage (data) {
  return window.axios({
    method: 'post',
    url: URL + 'chat-image',
    headers: headers,
    data: data
  })
}

export function postAutoTrust () {
  return window.axios({
    method: 'post',
    url: URL+'auto-trust',
    headers: headers
  })
}
// vitalsign?groupId=9
// https://api.vwell.care/cms/label/GetByParameters?group_stock=2&user_id=3&visit_id=209497&order_id_list&per_status=T&transfer_dt=
// cms-diag?visit_id=0&rid=400&cont=0&keyword=pain
// cms-group?group_id=10&group_stock=0&group_stamp=0
// cms-shifts?user_id=0&group_id=10&user_group_list=CAR|DOC|ORT&start_date=2021-5-01&ot_status=