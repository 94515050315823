// import colors from 'vuetify/lib/util/colors'

export default {
  primary: {
    base: '#EC2878',
    lighten4: '#fce3ec',
    lighten3: '#f8b7cf',
    lighten2: '#f488af',
    lighten1: '#f0558f',
    darken1: '#d8005b',
    darken2: '#c20057',
    darken3: '#ae0053',
    darken4: '#88004D'
  },
  secondary: {
    base: '#EC2878',
    lighten4: '#F383B0',
    lighten3: '#F6A1C4',
    lighten2: '#F8C0D7',
    lighten1: '#FBDFEA',
    darken1: '#CA2266',
    darken2: '#A81C55',
    darken3: '#861644',
    darken4: '#651133',
  },
  error: {
    base: '#EF4044',
    lighten2: '#F58C8F',
    lighten1: '#FCD9DA',
    darken1: '#BF3336',
    darken2: '#8F2629',
  },
  warning: {
    base: '#F1C849',
    lighten2: '#F7DE92',
    lighten1: '#FCF4DB',
    darken1: '#C1A03A',
    darken2: '#91782C'
  },
  info: {
    base: '#2F80ED',
    lighten2: '#82B3F4',
    lighten1: '#D5E6FB',
    darken1: '#2666BE',
    darken2: '#1C4D8E',
  },
  success: {
    base: '#27AE60',
    lighten2: '#7DCEA0',
    lighten1: '#D4EFDF',
    darken1: '#1F8B4D',
    darken2: '#17683A',
  },
  natural: {
    base: '#FFFFFF',
    lighten4: '#FAFAFC',
    lighten3: '#F2F2F5',
    lighten2: '#EBEBF0',
    lighten1: '#E3E4EC',
    darken1: '#C7C9D9',
    darken2: '#8F90A6',
    darken3: '#4E4B66',
    darken4: '#0E0E27',
  },
  // All keys will generate theme styles,
  // Here we add a custom `tertiary` color
 
}